import {React, useEffect, useState,useRef,useCallback} from 'react'
import './entry.css'
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import {ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import {
    GoogleMap,
    LoadScript,
    DirectionsService,
    DirectionsRenderer,
    Autocomplete,
    useLoadScript
  } from '@react-google-maps/api';
  import Form from 'react-bootstrap/Form';
  import FloatingLabel from 'react-bootstrap/FloatingLabel';
  import Axios from 'axios'
  import 'bootstrap/dist/css/bootstrap.min.css';

const containerStyle = {
    width: '100%',
    height: '580px'
  };
  
  const center = {
    lat: -1.286389,
    lng: 36.817223
  };


export default function Entry({storeid}) {
    const [origin, setOrigin] = useState('Naivas Prestige');
    const [destination, setDestination] = useState('');
    const [response, setResponse] = useState(null);
    const [distance, setDistance] = useState('');
    const originRef = useRef(null);
    const destinationRef = useRef(null);
    
    const[orderNumber, setOrdernumber] = useState('');
    const[orderValue, setOrdervalue] = useState('');
    const[customername, setCustomername] = useState('');
    const[customerphone, setCustomerphone] = useState('');
    const[deliverylocation, setDeliverylocation] = useState('');
    const[landmark, setLandmark] = useState('');
    const[instructions, setInstructions] = useState('');
    const[isDistance, setIsdistance] = useState(false)

    let dis = ''

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyBocgIcE-rvS4tJpT-4JkxIGoTVaWxjDHk',
        libraries: ['places'],
      });

      const directionsCallback = useCallback((result, status) => {
        if (status === 'OK' && result) {
          setResponse(result);
          const route = result.routes[0];
          const distanceText = route.legs[0].distance.text;
      
          // Extract the numeric value from the distance string
          const distanceValue = parseFloat(distanceText.replace('km', '').trim());
      
          setDistance(distanceValue);
          setIsdistance(true); // Set the flag to true when distance is updated
        }
      }, []);
    
      const handleFindRoute = useCallback(() => {
        // setOrigin(originRef.current.value);
        setDestination(destinationRef.current.value);
      }, []);
    
      const handleDirectionsRequest = useCallback(() => {
        if (origin && destination) {
          const DirectionsServiceOptions = {
            destination: destination,
            origin: origin,
            travelMode: 'DRIVING'
          };
    
          return <DirectionsService options={DirectionsServiceOptions} callback={directionsCallback} />;
        }
        return null;
      }, [origin, destination, directionsCallback]);

      const handleOrder = ()=>{
        if(orderNumber !='' && orderValue != '' && customername !='' && customerphone !='' && landmark !=''){
            
            handleFindRoute();
           
        }
        else{
            toast.error('All fiends are required')
        }
      }
      useEffect(()=>{
        if(isDistance){
            Axios.post('https://yunshuapi1.uuidesign.xyz/api/postOrder',{
                orderNumber,orderValue,customername,customerphone,destination:destinationRef.current.value,landmark,instructions,storeid,distance
            }).then((res)=>{

                if(res.data.success){
                    setOrdernumber("")
                    setOrdervalue('')
                    setCustomername('')
                    setCustomerphone('')
                    destinationRef.current.value = ''
                    setLandmark('')
                    setInstructions('')
                    toast.success('Order submitted successfully')

                    
                }
                else{
                    toast.error('Something went wrong. Please contact support')
                }
            }).catch((err)=>{
                toast.error('Error! Cannot connect to the server. Please contact support')
            })
        }
      },[isDistance])
    
      if (loadError) {
        return <div>Error loading maps</div>;
      }
    
      if (!isLoaded) {
        return <div>Loading Maps...</div>;
      }





  
  return (
    <div className='entryWrapper'>
        <ToastContainer />
        <div className="entryMap">
        <div>
        <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
      >
        {handleDirectionsRequest()}
        {response && <DirectionsRenderer directions={response} />}
      </GoogleMap>
      <div>
        {distance && <h4>Distance: {distance}</h4>}
      </div>
    </div>

        </div>
        <div className="entryFields">
            <h5>Fill the fields below</h5>
            <p style={{color:'red'}}>All fields are mandatory*</p>


            <FloatingLabel  className='formInput' controlId="floatingInputGrid" label="Order Number">
                <Form.Control onChange={(e)=>{setOrdernumber(e.target.value)}} value={orderNumber}  className='inputSearch'  type="text" placeholder="Order number" />
            </FloatingLabel>
            <FloatingLabel  className='formInput' controlId="floatingInputGrid" label="Order value">
                <Form.Control onChange={(e)=>{setOrdervalue(e.target.value)}} value={orderValue}  className='inputSearch'  type="text" placeholder="Order value" />
            </FloatingLabel>
            <FloatingLabel  className='formInput' controlId="floatingInputGrid" label="Customer name">
                <Form.Control onChange={(e)=>{setCustomername(e.target.value)}} value={customername}  className='inputSearch'  type="text" placeholder="Customer name" />
            </FloatingLabel>
            <FloatingLabel  className='formInput' controlId="floatingInputGrid" label="Customer phone">
                <Form.Control onChange={(e)=>{setCustomerphone(e.target.value)}} value={customerphone}  className='inputSearch'  type="text" placeholder="Customer phone" />
            </FloatingLabel>

            <Autocomplete>
            <FloatingLabel  className='formInput' controlId="floatingInputGrid" label="Delivery location">
                <Form.Control ref={destinationRef}   className='inputSearch'  type="text" placeholder="Delivery location" />
            </FloatingLabel>
            </Autocomplete>

            <FloatingLabel  className='formInput' controlId="floatingInputGrid" label="Closest landmark">
                <Form.Control onChange={(e)=>{setLandmark(e.target.value)}} value={landmark}  className='inputSearch'  type="text" placeholder="Closest landmark" />
            </FloatingLabel>
            <FloatingLabel className='formInput' controlId="floatingTextareaGrid" label="Special instructions">
            <Form.Control 
                as="textarea" 
                className='inputSearch'  
                placeholder="Special instructions" 
                onChange={(e)=>{setInstructions(e.target.value)}} 
                value={instructions}
                style={{ height: '100px' }} // Adjust height as needed
            />
            </FloatingLabel>
            <Button color='danger' onClick={handleOrder} className='placeorderBtn'>Place order</Button>
            

        </div>

    </div>
  )
}
