import {React, useState, useEffect }from 'react'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import {Button} from 'reactstrap'
import Form from 'react-bootstrap/Form';
import Axios from 'axios'
import 'bootstrap/dist/css/bootstrap.min.css';
import './login.css';
import {ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'react-router-dom';

export default function Login() {
    const[storeid, setStoreid] = useState("");
    const[storepass, setStorepass] = useState("")
    const navigate = useNavigate();

    Axios.defaults.withCredentials = true;
    let mail = ''
    useEffect(() =>{
      Axios.get('https://yunshuapi1.uuidesign.xyz/api/profile').then((response)=>{
        if(response.data.valid){
        navigate('/home')
        }
        else{
          //pass
         
        }
      
      })
    },[])

    const login = ()=>{
        if(storeid != '' && storepass != ''){
            Axios.post('https://yunshuapi1.uuidesign.xyz/api/login',{
                storeid,storepass
            }).then((res)=>{
                if(res.data.Login){
                    navigate('/home')
                }
                else{
                    toast.error('Store credentials do not match')
                }
            })
        }
        else{
            toast.error('All fields are required')
        }
    }


  return (
    <div className='loginWrapper'>
        <ToastContainer />
        <div className="loginContainer">
            <img src="/assets/yunshulogo.jpg" alt="logo" className='logo' />
            <h5>Yunshu Africa Limited</h5>
        

        <FloatingLabel className='formInput' onChange={(e)=>{setStoreid(e.target.value)}} controlId="floatingInputGrid" label="Store id">
              <Form.Control  className='inputSearch'  type="text" placeholder="Store-id" />
          </FloatingLabel>
          <FloatingLabel  onChange={(e)=>{setStorepass(e.target.value)}} className='formInput' controlId="floatingInputGrid" label="Store pass">
              <Form.Control  className='inputSearch'  type="password" placeholder="Store pass" />
          </FloatingLabel>
          <div className="signinBtn">
            <Button onClick={login} className='loginBtn' color='success'>Sign in</Button>
            <a className='forgot' href="#">Forgot password?</a>
          </div>
          
            
        </div>

    </div>
  )
}
